import React, { useState, useEffect } from "react";
import "../css/Login.css";
import { Button, Input, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import getApiClient from "../axios/axios";
import { Modal, Box, Typography } from "@mui/material";
import OTPInput from "otp-input-react";
import { CircularProgress } from "@mui/material";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otptoken, setOtpToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleLogin = async () => {
    setLoading(true);
    setApiError(false);
    const axios = await getApiClient();
    const response = await axios.post("/v1/admin/login", {
      email: email,
      password: password,
    });
    if (response?.data?.success === true) {
      setOpen(true);
      setLoading(false);
      setOtpToken(response?.data?.data?.verification_key);
    } else {
      setApiError(true);
      setLoading(false);
    }
    return response;
  };
  const handleSubmit = async () => {
    setLoading(true);
    setApiError(false);
    const axios = await getApiClient();
    const response = await axios.post("/v1/admin/verify", {
      otp: otp,
      token: otptoken,
    });
    if (response?.data?.success === true) {
      setOpen(true);
      setLoading(false);
      localStorage.setItem("token", response?.data?.data?.token);
      navigate("/home/manage");
    } else {
      setApiError(true);
      setLoading(false);
    }
    return response;
  };

  const handleModal = () => {
    setOpen(!open);
  };
  return (
    <div className="login_container">
      <h1>Admin Login</h1>
      <div className="login_inner">
        <TextField
          label="Email"
          type="email"
          value={email}
          placeholder={"please enter your email"}
          onChange={(e) => {
            setApiError(false);
            setEmail(e.target.value);
          }}
        />

        <TextField
          label="Password"
          type="password"
          value={password}
          placeholder={"please enter password"}
          onChange={(e) => {
            setApiError(false);
            setPassword(e.target.value);
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            handleLogin();
          }}
        >
          {loading ? (
            <CircularProgress size={25} style={{ color: "#fff" }} />
          ) : (
            <span style={{ color: "#fff" }}>Login</span>
          )}
        </Button>
        {apiError && (
          <p style={{ fontSize: "1.5rem", textAlign: "center", color: "red" }}>
            Cannot send otp!
          </p>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setOtp("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter otp
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <OTPInput
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              className="otp"
              value={otp}
              onChange={setOtp}
            />
            <Button
              variant="contained"
              style={{ marginTop: "5%" }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading ? (
                <CircularProgress size={25} style={{ color: "#fff" }} />
              ) : (
                <span style={{ color: "#fff" }}>Submit</span>
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Login;
