import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import "../../css/CategoryModal.css";
import {
  Add,
  Close,
  CloudUpload,
  Delete,
  DeleteOutline,
} from "@mui/icons-material";
import getApiClient from "../../axios/axios";
const PackageModal = ({
  open,
  setOpen,
  data,
  setData,
  isEdit,
  setEdit,
  refetch,
  service,
  sub_category,
}) => {
  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "70vh",
    overflowY: "auto",
    p: 4,
  };
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [price, setPrice] = useState();
  const [requirements, setRequirements] = useState([]);
  const [newRequirement, setNewRequirement] = useState("");
  const UploadImage = async (e) => {
    const axios = await getApiClient();
    const file = e.target.files[0];
    setImageLoading(true);
    const formData = new FormData();
    let image = e.target.files[0];

    formData.append("image", e.target.files[0]);
    for (var key of formData.entries()) {
    }
    const response = await axios.post("/v1/upload/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response?.data?.picture) {
      setImageLoading(false);
      setImage(response?.data?.picture);
    } else {
      console.log("error");
      setImageLoading(false);
    }
  };

  const handleSubmit = async () => {
    const axios = await getApiClient();

    try {
      const response = await axios.post("/v1/jadzzo-package/add-package", {
        name: name,
        package_image: image,
        service: service?.toString(),
        sub_category: sub_category?.toString(),
        price: price?.toString(),
        details: requirements,
      });
      if (response?.data?.success === true) {
        setOpen(false);
        alert("success");
        setName("");
        setImage("");
        setPrice("");
        setRequirements([]);
        refetch();
      } else {
        alert(response?.data?.error?.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleEdit = async () => {
    const axios = await getApiClient();
    try {
      const response = await axios.post("/v1/jadzzo-package/update-package", {
        name: name,
        package_image: image,
        price: price?.toString(),
        details: requirements,
        jadzzopackage_id: data?.package?.jadzzopackage_id?.toString(),
      });
      if (response?.data?.success === true) {
        setOpen(false);
        alert("success");
        setName("");
        setImage("");
        setPrice("");
        setRequirements([]);
        refetch();
      } else {
        alert(response?.data?.error?.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  const getAllRequirements = async () => {
    const axios = await getApiClient();
    const response = await axios.post("/v1/service/getallrequirements", {
      service_id: data?.service_id,
      sub_category_id: data?.subcategory_id,
    });
    if (response?.data?.success === true) {
      setRequirements(response?.data?.data?.requirements);
    } else {
      alert(response?.data?.error?.message);
    }
  };

  const addRequirement = async (data) => {
    setRequirements([...requirements, { text: data }]);
    setNewRequirement("");
  };
  const deleteRequirement = async (data) => {
    setRequirements(requirements?.filter((item) => item?.text !== data));
    console.log(requirements);
  };
  useEffect(() => {
    console.log(data, isEdit);
    if (isEdit) {
      setName(data?.package?.name);
      setImage(data?.package?.package_image);
      for (let i = 0; i < data?.package?.details?.length; i++) {
        delete data?.package?.details[i]?._id;
      }
      setRequirements(data?.package?.details);
      setPrice(data?.package?.price);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);

        setPrice("");
        setImage("");
        setRequirements([]);
        console.log("hello");
        setNewRequirement("");
        setName("");
        setEdit(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Enter details{" "}
          <Close
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="form_inner">
            <TextField
              label="Name"
              type="text"
              value={name}
              placeholder={"please enter package name"}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <TextField
              label="Price"
              type="number"
              style={{
                marginTop: "2rem",
              }}
              value={price}
              placeholder={"please enter price"}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
            />
            <label className="file_upload">
              <input
                type={"file"}
                accept=".jpg, .jpeg, .png, .webp"
                onChange={(e) => UploadImage(e)}
              />
              <CloudUpload />
              <span> {image ? image : "no file chosen"}</span>
              {imageLoading && (
                <p>
                  <CircularProgress size={20} />
                </p>
              )}
            </label>
            <span
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                color: "red",
              }}
              onClick={() => {
                setImage("");
              }}
            >
              {" "}
              {image ? (
                <>
                  <Delete />
                  remove
                </>
              ) : (
                ""
              )}
            </span>
            {image ? (
              <img className="file_upload_image" src={image} alt={"asd"} />
            ) : null}

            <div>
              <TextField
                label="Requirement"
                type="text"
                value={newRequirement}
                style={{ width: "100%", marginTop: "4%", marginBottom: "4%" }}
                placeholder={"please enter requirement"}
                onChange={(e) => {
                  setNewRequirement(e.target.value);
                }}
              />
              <Button
                variant="outlined"
                color="success"
                startIcon={<Add color="success" />}
                onClick={() => {
                  addRequirement(newRequirement);
                }}
              >
                add requirement
              </Button>
              {requirements?.map((item) => {
                return (
                  <div className="list_action_container">
                    <Button
                      onClick={() => {
                        deleteRequirement(item?.text);
                      }}
                      startIcon={<DeleteOutline />}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "red",
                      }}
                    >
                      Delete
                    </Button>
                    <p style={{ fontSize: "18px" }}>{item?.text}</p>
                  </div>
                );
              })}
            </div>

            <Button
              variant="contained"
              style={{
                marginTop: "5%",
                width: "10rem",
                margin: "auto",
                marginTop: "3%",
              }}
              onClick={() => {
                if (isEdit) {
                  handleEdit();
                } else {
                  handleSubmit();
                }
              }}
            >
              {loading ? (
                <CircularProgress size={25} style={{ color: "#fff" }} />
              ) : (
                <span style={{ color: "#fff" }}>Save</span>
              )}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PackageModal;
