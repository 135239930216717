import React, { useState, useEffect } from "react";
import "../css/Locations.css";
import { Button, Input, TextField } from "@mui/material";
import getApiClient from "../axios/axios";
import { Add, Edit, DeleteOutline } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import LocationModal from "../components/LocationModal/LocationModal";
import SidePanel from "../components/SidePanel/SidePanel";
const Locations = () => {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [open, setOpen] = useState(false);
  const getAllLocation = async () => {
    setLoading(true);
    const axios = await getApiClient();
    try {
      const response = await axios.get("/v1/location/getallLocations");
      if (response?.data?.success === true) {
        setLocations(response?.data?.data?.locations);
        setLoading(false);
      } else {
        alert(response?.data?.error?.message);
        setLoading(false);
      }
    } catch (err) {
      alert(err);
      setLoading(false);
    }
  };
  const handleDelete = async (id) => {
    const axios = await getApiClient();

    try {
      const response = await axios.post("/v1/location/deletelocation", {
        location_id: id,
      });
      if (response?.data?.success === true) {
        alert("success");
        getAllLocation();
      } else {
        alert(response?.data?.error?.message);
      }
    } catch (err) {
      alert(err);
    }
  };
  useEffect(() => {
    getAllLocation();
  }, []);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <SidePanel />
      <div className="DrawerRight">
        <div className="manage_container">
          <div className="services_container">
            <h2>
              Locations{" "}
              <Button
                variant="outlined"
                color="success"
                startIcon={<Add color="success" />}
                onClick={() => {
                  setOpen(true);
                }}
              >
                add
              </Button>
            </h2>
            {locations?.map((item, i) => {
              return (
                <div className="list_item" key={i}>
                  <p key={i}>{item?.name}</p>
                  <div className="list_action_container">
                    <Button
                      startIcon={<DeleteOutline />}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "red",
                      }}
                      onClick={() => {
                        handleDelete(item?.location_id);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <LocationModal open={open} setOpen={setOpen} />
      </div>
    </>
  );
};

export default Locations;
