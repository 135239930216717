import React, { useState, useEffect } from "react";
import getApiClient from "../axios/axios";
import { Add, Edit, DeleteOutline } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DropDown from "../components/DropDown";
import "../css/Packages.css";
import PackageCard from "../components/PackageCard";
import PackageModal from "../components/PackageModal/PackageModal";
import { useSearchParams } from "react-router-dom";
const Packages = () => {
  const [services, setServices] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageArray, setPageArray] = useState([]);
  const [allDetails, setAllDetails] = useState();
  const [loading, setLoading] = useState([]);
  const [packages, setPackages] = useState([]);
  const [packageLoading, setPackageLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const getAllservices = async (call) => {
    const axios = await getApiClient();
    const response = await axios.get("/v1/service/getallservices");
    if (response.data?.status_code === 200) {
      let arr = [];

      setServices(response.data?.data?.services);
      if (call) {
        setSelectedService(response.data?.data?.services[0]?.service_id);
        await getAllSubCategories(
          response.data?.data?.services[0]?.service_id,
          call
        );
      }
    } else {
      console.log(response.data?.error);
    }
  };
  const getAllSubCategories = async (id, call) => {
    const axios = await getApiClient();

    let service_id = id ?? services[0]?.service_id;
    const response = await axios.post("/v1/service/getallsubcategories", {
      service_id: service_id,
    });
    if (response.data?.status_code === 200) {
      setSubCategories(response.data?.data?.sub_categories);
      if (call) {
        setSelectedSubCategory(
          response.data?.data?.sub_categories[0]?.subcategory_id
        );
        await getPackages(
          service_id,
          response.data?.data?.sub_categories[0]?.subcategory_id,
          false,
          1
        );
      }
    } else {
      console.log(response.data?.error);
    }
  };

  const getPackages = async (sid, subid, is_same_category, page) => {
    const axios = await getApiClient();

    let service_id = sid ?? services[0]?.service_id;
    let subcategory_id = subid ?? subCategories[0]?.subcategory_id;
    const response = await axios.post(`/v1/jadzzo-package/get-package`, {
      service: service_id,
      sub_category: subcategory_id,
    });
    if (response.data?.status_code === 200) {
      setPackages(response?.data?.data?.packages);

      setAllDetails(response?.data?.data);

      setLoading(false);
    } else {
      console.log(response.data?.error);
      setLoading(false);
    }
  };
  const handleServiceChange = async (id) => {
    setCurrentPage(0);
    setPackageLoading(true);

    await getAllSubCategories(id, true);
    setPackageLoading(false);
  };

  const handleSubCategoryChange = async (id) => {
    setCurrentPage(0);
    setPackageLoading(true);

    await getPackages(selectedService, id);
    setPackageLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      if (searchParams.get("service") && searchParams.get("sub_category")) {
        setSelectedService(searchParams.get("service").toString());
        setSelectedSubCategory(searchParams.get("sub_category"));

        await getAllservices();
        await getAllSubCategories(searchParams?.get("service"));
        await getPackages(
          searchParams.get("service"),
          searchParams.get("sub_category")
        );
      } else {
        await getAllservices(true);
      }
    };
    loadData();
  }, []);
  useEffect(() => {
    console.log(selectedService, selectedSubCategory);
    console.log(subCategories);
  }, [selectedService, selectedSubCategory]);
  return (
    <>
      <div className="package_container">
        <div className="dropdown_flex">
          <DropDown
            selected={
              services?.filter((data) => data?.service_id == selectedService)[0]
                ?.name
            }
            options={services?.map((data) => data?.name)}
            onSelect={(value) => {
              const service = services?.filter((data) => value == data?.name);
              setSelectedService(service[0]?.service_id);
              handleServiceChange(service[0]?.service_id);
            }}
            style={{
              width: "20rem",
            }}
          />
          <DropDown
            selected={
              subCategories?.filter(
                (data) => data?.subcategory_id == selectedSubCategory
              )[0]?.name
            }
            options={subCategories?.map((data) => data?.name)}
            onSelect={(value) => {
              const sub_category = subCategories?.filter(
                (data) => value == data?.name
              );
              setSelectedSubCategory(sub_category[0]?.subcategory_id);
              handleSubCategoryChange(sub_category[0]?.subcategory_id);
            }}
            style={{
              width: "20rem",
            }}
          />
        </div>
        <div
          style={{
            paddingLeft: "2%",
          }}
        >
          <Button
            variant="outlined"
            color="success"
            onClick={() => {
              setOpen(true);
            }}
          >
            ADD PACKAGE
          </Button>
        </div>
        <div className="package_grid_container">
          {packages?.map((item, i) => {
            return <PackageCard pck={item} key={i} />;
          })}
        </div>
      </div>
      <PackageModal
        open={open}
        setOpen={setOpen}
        isEdit={false}
        service={selectedService}
        sub_category={selectedSubCategory}
        refetch={async () => {
          await getPackages(selectedService, selectedSubCategory);
        }}
      />
    </>
  );
};

export default Packages;
