import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import getApiClient from "../axios/axios";
import "../css/PackageCard.css";
import { Link, useNavigate } from "react-router-dom";
import { Add, Edit, Delete } from "@mui/icons-material";
const PackageCard = ({ pck }) => {
  const navigate = useNavigate();
  return (
    <div className="package_item">
      <div className="package_item_img">
        {/* <div
          style={{
            position: "absolute",
            justifyContent: "flex-end",
            width: "100%",
            display: "flex",
            padding: "2px",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Delete
            style={{
              color: "red",
              cursor: "pointer",
            }}
          />
          <Edit
            style={{
              color: "#777777",
              cursor: "pointer",
            }}
          />
        </div> */}
        <Link to={`/packages/details/${pck?.jadzzopackage_id}`}>
          <img src={pck?.package_image} alt="jadzzo package" />
        </Link>
      </div>

      <div className="package_card_details_container">
        <Link to={`/packages/details/${pck?.jadzzopackage_id}`}>
          <div>
            <h3>{pck?.name}</h3>
            <h2>
              <span>₹</span> {parseInt(pck?.price)?.toLocaleString("en-IN")}
            </h2>
            <p>You'll get</p>
            {pck?.details?.map((item, i) => {
              return (
                <div className="package_points" key={i}>
                  <span>*</span>
                  <p>{item?.text}</p>
                </div>
              );
            })}
          </div>
        </Link>
      </div>

      <div className="book_btn_container">
        {/* <div className="rating_container">
			{#if pck?.rating?.length > 0}
				<div className="default_star">
					<GroupedStar width={100} height={20} clip={false} color="#F0F0F0" />
				</div>
				<div className="default_star">
					<GroupedStar width={100} height={20} rating={pck?.avg_rating?.toFixed(1)} />
				</div>

				&nbsp;<span className="rating_text">{pck?.avg_rating?.toFixed(1)}</span>
			{:else}
				<p />
			{/if}
		</div> */}
        <Button
          variant="contained"
          onClick={() => {
            navigate(`/packages/details/${pck?.jadzzopackage_id}`);
          }}
        >
          Details
        </Button>
      </div>
    </div>
  );
};

export default PackageCard;
