import React, { useEffect, useState } from "react";
import getApiClient from "../axios/axios";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import "../css/PackageDetails.css";
import PackageModal from "../components/PackageModal/PackageModal";
const PackageDetails = () => {
  let { id } = useParams();
  const [packageDetails, setPackageDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const getPackageDetails = async () => {
    setLoading(true);
    const axios = await getApiClient();
    const response = await axios.post(
      "/v1/jadzzo-package/get-package-details",
      {
        package: id,
      }
    );
    if (response?.data?.status_code === 200) {
      setPackageDetails(response?.data?.data);
      setLoading(false);
    } else {
      alert("something went wrong");
      setLoading(false);
    }
  };
  const deletePackage = async (id) => {
    const axios = await getApiClient();
    const response = await axios.post("/v1/jadzzo-package/delete-package", {
      package_id: id,
    });
    if (response?.data?.success === true) {
      alert("success");
      navigate(
        `/packages?service=${packageDetails?.package?.service}&sub_category=${packageDetails?.package?.sub_category}`
      );
    } else {
      alert("failed");
    }
  };
  useEffect(() => {
    getPackageDetails();
  }, []);
  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className="package_details_container">
        <div className="left">
          <div className="package_image">
            <img src={packageDetails?.package?.package_image} alt="asd" />
          </div>
          <p
            style={{
              marginTop: "5px",
            }}
          >
            {packageDetails?.package?.name}
          </p>
          <p
            style={{
              color: "var(--tertiary)",
              marginTop: "5px",
            }}
          >
            Rs{" "}
            {parseInt(packageDetails?.package?.price)?.toLocaleString("en-IN")}
          </p>
          <p
            style={{
              color: "orange",
            }}
          >
            Package includes:
          </p>
          <ul>
            {packageDetails?.package?.details?.map((item) => {
              return <li>{item?.text}</li>;
            })}
          </ul>
        </div>
        <div className="right">
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                deletePackage(packageDetails?.package?.jadzzopackage_id);
              }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="info"
              onClick={() => {
                setOpen(true);
                setEdit(true);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
      <PackageModal
        open={open}
        setOpen={setOpen}
        data={packageDetails}
        isEdit={edit}
        setEdit={setEdit}
        service={packageDetails?.package?.service}
        refetch={async () => {
          await getPackageDetails();
        }}
        sub_category={packageDetails?.package?.sub_category}
      />
    </>
  );
};

export default PackageDetails;
