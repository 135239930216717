import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import "../../css/ServiceModal.css";
import { Close, CloudUpload, Delete } from "@mui/icons-material";
import getApiClient from "../../axios/axios";
const ServiceModal = ({
  open,
  setOpen,
  data,
  isEdit,
  setData,
  setEdit,
  refetch,
}) => {
  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "70vh",
    overflowY: "auto",
    p: 4,
  };
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const UploadImage = async (e) => {
    const axios = await getApiClient();
    const file = e.target.files[0];
    setImageLoading(true);
    const formData = new FormData();
    let image = e.target.files[0];

    formData.append("image", e.target.files[0]);
    for (var key of formData.entries()) {
    }
    const response = await axios.post("/v1/upload/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response?.data?.picture) {
      setImageLoading(false);
      setImage(response?.data?.picture);
    } else {
      console.log("error");
      setImageLoading(false);
    }
  };
  const handleSubmit = async () => {
    const axios = await getApiClient();
    try {
      const response = await axios.post("/v1/service/createservice", {
        name: name,
        image: image,
      });
      if (response?.data?.success === true) {
        setOpen(false);
        alert("success");
        refetch();
        setName("");
        setImage("");
      } else {
        alert(response?.data?.error?.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleEdit = async () => {
    const axios = await getApiClient();
    try {
      const response = await axios.post("/v1/service/updateservice", {
        name: name,
        image: image,
        service_id: data?.service_id,
      });
      if (response?.data?.success === true) {
        setOpen(false);
        alert("success");
        setName("");
        setImage("");
        refetch();
      } else {
        alert(response?.data?.error?.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setName(data?.name);
      setImage(data?.image);
    }
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={() => {
        setData("");
        setEdit(false);
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Enter details{" "}
          <Close
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="form_inner">
            <TextField
              label="Name"
              type="text"
              value={name}
              placeholder={"please enter your name"}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <label className="file_upload">
              <input
                type={"file"}
                accept=".jpg, .jpeg, .png, .webp"
                onChange={(e) => UploadImage(e)}
              />
              <CloudUpload />
              <span> {image ? image : "no file chosen"}</span>
              {imageLoading && (
                <p>
                  <CircularProgress size={20} />
                </p>
              )}
            </label>
            <span
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                color: "red",
              }}
              onClick={() => {
                setImage("");
              }}
            >
              {" "}
              {image ? (
                <>
                  <Delete />
                  remove
                </>
              ) : (
                ""
              )}
            </span>
            {image ? (
              <img className="file_upload_image" src={image} alt={"asd"} />
            ) : null}
            <Button
              variant="contained"
              style={{
                marginTop: "5%",
                width: "10rem",
                margin: "auto",
                marginTop: "3%",
              }}
              onClick={() => {
                if (isEdit) {
                  handleEdit();
                } else {
                  handleSubmit();
                }
              }}
            >
              {loading ? (
                <CircularProgress size={25} style={{ color: "#fff" }} />
              ) : (
                <span style={{ color: "#fff" }}>Submit</span>
              )}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ServiceModal;
