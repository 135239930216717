import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import "../../css/LocationModal.css";
import { Close, CloudUpload, Delete } from "@mui/icons-material";
import getApiClient from "../../axios/axios";
const LocationModal = ({ open, setOpen }) => {
  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "70vh",
    overflowY: "auto",
    p: 4,
  };
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const handleSubmit = async () => {
    const axios = await getApiClient();

    try {
      const response = await axios.post("/v1/location/createlocation", {
        name: name,
      });
      if (response?.data?.success === true) {
        setOpen(false);
        alert("success");
        setName("");
      } else {
        alert(response?.data?.error?.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setName("");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Enter details{" "}
          <Close
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="form_inner">
            <TextField
              label="Name"
              type="text"
              value={name}
              placeholder={"please enter your name"}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Button
              variant="contained"
              style={{ width: "10rem", margin: "auto", marginTop: "3%" }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading ? (
                <CircularProgress size={25} style={{ color: "#fff" }} />
              ) : (
                <span style={{ color: "#fff" }}>Submit</span>
              )}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LocationModal;
