import React, { useState, useEffect } from "react";
import "../css/Manage.css";
import { Button, Input, TextField } from "@mui/material";
import getApiClient from "../axios/axios";
import { Add, Edit, DeleteOutline } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import ServiceModal from "../components/ServiceModal/ServiceModal";
import CategoryModal from "../components/CategoryModal/CategoryModal";
import SidePanel from "../components/SidePanel/SidePanel";
import { Link } from "react-router-dom";
const Manage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [misc_services, setMiscServices] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [serviceEdit, setServiceEdit] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [categoryEdit, setCategoryEdit] = useState(false);
  const [categoryService, setCategoryService] = useState("");
  const getGroupedServices = async () => {
    setLoading(true);
    const axios = await getApiClient();
    try {
      const response = await axios.get("/v1/service/all");
      if (response?.data?.success === true) {
        setCategories(response?.data?.data?.all);
        setMiscServices(response?.data?.data?.misc_services);
        setLoading(false);
      } else {
        console.log(response?.data?.error);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const getAllServices = async () => {
    const axios = await getApiClient();
    const response = await axios.get("/v1/service/getallservices");
    if (response.data?.status_code === 200) {
      setServices(response.data?.data?.services);
    } else {
      console.log(response.data?.error);
    }
  };
  const deleteService = async (id) => {
    const axios = await getApiClient();
    const response = await axios.post("/v1/service/deleteservice", {
      service_id: id,
    });
    if (response.data?.status_code === 200) {
      alert("success");
      refetch();
    } else {
      console.log(response.data?.error);
    }
  };
  const deleteCategory = async (id) => {
    const axios = await getApiClient();
    console.log(id);
    const response = await axios.post("/v1/service/deletecategory", {
      subcategory_id: id,
    });
    if (response.data?.status_code === 200) {
      alert("success");
      await refetch();
    } else {
      console.log(response.data?.error);
    }
  };
  useEffect(() => {
    const loadData = async () => {
      await getAllServices();
      await getGroupedServices();
    };
    loadData();
  }, []);
  const refetch = async () => {
    await getAllServices();
    await getGroupedServices();
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <SidePanel />
      <div className="DrawerRight">
        <div className="manage_container">
          <div className="services_container">
            <h2>
              Services{" "}
              <Button
                variant="outlined"
                color="success"
                startIcon={<Add color="success" />}
                onClick={() => {
                  setServiceModal(true);
                }}
              >
                add service
              </Button>
            </h2>
            {services?.map((item, i) => {
              return (
                <div key={i}>
                  <div className="list_item">
                    <p key={i}>{item?.name}</p>
                    <div className="list_action_container">
                      <Button
                        startIcon={<DeleteOutline />}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "red",
                        }}
                        onClick={() => {
                          deleteService(item?.service_id);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        startIcon={<Edit />}
                        onClick={() => {
                          setServiceModal(true);
                          setServiceEdit(true);
                          setSelectedService(item);
                        }}
                      >
                        edit
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {misc_services?.map((item, index) => {
            return (
              <div className="services_container">
                <div key={index}>
                  <h2>
                    {item?.name}{" "}
                    <Button
                      variant="outlined"
                      color="success"
                      startIcon={<Add color="success" />}
                      onClick={() => {
                        setCategoryModal(true);
                        setSelectedCategory(item);

                        setCategoryService(item?.service_id);
                      }}
                    >
                      add category
                    </Button>
                  </h2>
                </div>
              </div>
            );
          })}
          <div className="services_container">
            <h2>Categories </h2>
            {categories?.map((item, i) => {
              return (
                <div key={i}>
                  <h2>
                    {item?.service}{" "}
                    <Button
                      variant="outlined"
                      color="success"
                      startIcon={<Add color="success" />}
                      onClick={() => {
                        setCategoryModal(true);
                        setCategoryService(item?.service_id);
                      }}
                    >
                      add category
                    </Button>
                  </h2>
                  {item?.sub_categories?.map((item2, i2) => {
                    return (
                      <div className="list_item" key={i2}>
                        <p key={i}>{item2?.name}</p>
                        <div className="list_action_container">
                          <Link
                            to={"/packages"}
                            style={{
                              fontSize: "1.3rem",
                            }}
                          >
                            View packages
                          </Link>
                          <Button
                            startIcon={<DeleteOutline />}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "red",
                              fontSize: "1.2rem",
                            }}
                            onClick={() => {
                              deleteCategory(item2?.subcategory_id);
                            }}
                          >
                            Delete
                          </Button>
                          <Button
                            startIcon={<Edit />}
                            style={{
                              fontSize: "1.2rem",
                            }}
                            onClick={() => {
                              console.log(item);
                              setCategoryModal(true);
                              setCategoryEdit(true);
                              setSelectedCategory({
                                service_id: item?.service_id,
                                ...item2,
                              });
                            }}
                          >
                            edit
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        <ServiceModal
          open={serviceModal}
          setOpen={setServiceModal}
          data={selectedService}
          setData={setSelectedService}
          isEdit={serviceEdit}
          setEdit={setServiceEdit}
          refetch={refetch}
        />
        <CategoryModal
          open={categoryModal}
          setOpen={setCategoryModal}
          data={selectedCategory}
          setData={setSelectedCategory}
          isEdit={categoryEdit}
          setEdit={setCategoryEdit}
          refetch={refetch}
          service={categoryService}
        />
      </div>
    </>
  );
};

export default Manage;
