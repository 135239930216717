import {
  BrowserRouter,
  Route,
  Routes,
  Switch,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import Manage from "../../pages/Manage";
import { useState } from "react";
import "../../css/SidePanel.css";
import { Close } from "@mui/icons-material";
const SidePanel = (props) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <label
        className="burger_menu"
        onClick={() => {
          setOpen(true);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </label>
      <div className={`side_panel_container ${open ? "open" : ""}`}>
        {open && (
          <div
            className="close_icon"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close style={{ float: "right", width: 40, height: 40 }} />
          </div>
        )}
        <h1>Jadzzo</h1>
        <p onClick={() => navigate("/home/manage")}>Services</p>
        <p onClick={() => navigate("/home/location")}>Locations</p>
      </div>
    </>
  );
};

export default SidePanel;
