import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import React, { useEffect, useState } from "react";
import Manage from "./pages/Manage";
import SidePanel from "./components/SidePanel/SidePanel";
import SideRoute from "./components/SideRoute/SideRoute";
import Locations from "./pages/Locations";
import Packages from "./pages/Packages";
import PackageDetails from "./pages/PackageDetails";
function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthenticated(true);
    }
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Login />}></Route>
          {authenticated && (
            <Route path="/home/manage" exact element={<Manage />} />
          )}
          {authenticated && (
            <Route path="/packages" exact element={<Packages />} />
          )}
          {authenticated && (
            <Route path="/home/location" exact element={<Locations />} />
          )}
          {authenticated && (
            <Route
              path="/packages/details/:id"
              exact
              element={<PackageDetails />}
            />
          )}
          {/*  <Route path="/home/*" exact element={<SideRoute />}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
